import React from 'react';
import { DefaultType } from 'types/default';
import { PostType } from 'types/know-how-hub-page.d';

import ArticleCard from 'components/molecules/article-card/article-card';
import Slider, { SliderType } from 'components/molecules/slider/slider';
import Button, { ButtonType } from 'components/atoms/button';

import { breakpoints, useScreenService } from 'hooks/use-screen-service';

import {
  StyledContent,
  StyledContentHeader,
  StyledContentWrapper,
  StyledSubTitle,
  StyledText,
  StyledTitle,
  StyledWrapper,
} from './posts-section-row-slider.styles';

export type PostsSectionRowSliderType = {
  heading?: string;
  subTitle?: string;
  text?: string;
  linkButton?: ButtonType;
  posts?: PostType[];
} & DefaultType;

const PostsSectionRowSlider = ({
  heading,
  subTitle,
  text,
  linkButton,
  posts = [],
  margin,
  mobileMargin,
  mobilePadding,
  padding,
  bgColor,
}: PostsSectionRowSliderType) => {
  const cardsSlides = posts
    ? posts.map(({ featuredImage, title, date, categories, tags, link, id }) => (
        <ArticleCard
          tags={tags}
          categories={categories}
          date={date}
          key={id}
          title={title}
          postButton={{
            label: 'Czytaj dalej',
            ariaLabel: 'Czytaj dalej',
          }}
          uri={link}
          featuredImage={featuredImage}
        />
      ))
    : [];

  const cardSliderOptions: SliderType['options'] = {
    slidesPerView: 1.2,
    spaceBetween: 16,
    watchSlidesProgress: true,
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 2.2,
      },
      [breakpoints.md]: {
        slidesPerView: 3,
      },
    },
  };

  const { isMobile } = useScreenService();

  return (
    <StyledWrapper
      margin={margin}
      mobileMargin={mobileMargin}
      mobilePadding={mobilePadding}
      padding={padding}
      bgColor={bgColor}
    >
      <StyledContent>
        <StyledSubTitle variant="title6" color="gray40" html={subTitle} />
        <StyledContentWrapper>
          <StyledContentHeader isText={text}>
            <StyledTitle
              variant="title2"
              color="gray100"
              component="h2"
              html={heading}
              isText={text}
            />
            {!isMobile ? (
              <StyledText variant="body1" color="gray100" component="h2" html={text} />
            ) : null}
            <Button {...linkButton} variant="underline" width="max-content" />
          </StyledContentHeader>
          <Slider
            {...{
              slides: cardsSlides,
              options: cardSliderOptions,
              variant: 'secondary',
            }}
          />
        </StyledContentWrapper>
      </StyledContent>
    </StyledWrapper>
  );
};

export default PostsSectionRowSlider;
