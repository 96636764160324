import React from 'react';
import { DefaultType } from 'types/default';

import {
  StyledContent,
  StyledDescription,
  StyledProblem,
  StyledProblems,
  StyledProblemTitle,
  StyledTitle,
  StyledWrapper,
} from './industries-about.styles';

export type IndustriesAboutType = {
  heading: string;
  text: string;
  problemHeading?: string;
  problemsList: { text: string }[];
  smallHeading?: boolean;
  mediumHeading?: boolean;
} & DefaultType;

const IndustriesAbout = ({
  heading,
  text,
  problemHeading,
  problemsList,
  smallHeading,
  mediumHeading,
  maxWidth,
  margin,
  padding,
  mobileMargin,
  mobilePadding,
  bgColor,
}: IndustriesAboutType) => {
  const titleVariant = () => {
    if (smallHeading) return 'title3';
    if (mediumHeading) return 'title2';
    return 'title1';
  };

  return (
    <StyledWrapper
      margin={margin}
      mobileMargin={mobileMargin}
      padding={padding}
      mobilePadding={mobilePadding}
      bgColor={bgColor}
    >
      <StyledContent maxWidth={maxWidth}>
        <StyledTitle html={heading} variant={titleVariant()} />
        <StyledDescription html={text} variant="body1" />
        {problemHeading && (
          <StyledProblemTitle variant="title3" component="h3">
            {problemHeading}
          </StyledProblemTitle>
        )}
        <StyledProblems isProblemHeading={!!problemHeading}>
          {problemsList
            ? problemsList.map((problem) => (
                <StyledProblem component="li" variant="body1" key={problem.text}>
                  {problem.text}
                </StyledProblem>
              ))
            : null}
        </StyledProblems>
      </StyledContent>
    </StyledWrapper>
  );
};

export default IndustriesAbout;
