import React from 'react';

import { Button, ButtonType } from 'components/atoms/button';
import { WpImageType } from 'components/atoms/wp-image';

import { DefaultType } from '../../../types/default';

import {
  StyledBanner,
  StyledContainerContentText,
  StyledDescription,
  StyledDescriptionSmall,
  StyledHeading,
  StyledHeadingSmall,
  StyledWrapper,
  StyledWrapperBanner,
  StyledWrapperContentText,
  StyleSubHeading,
  StyleSubHeadingSmall,
} from './half-banner-description.styles';

export type HalfBannerDescriptionType = {
  bannerImage: WpImageType;
  bannerLinkButton: ButtonType;
  bannerRight: boolean;
  isSmall: boolean;
} & Record<'bannerHeading' | 'bannerSubHeading' | 'bannerText', string> &
  DefaultType;
export type BannerPositionRightType = {
  positionRight: boolean;
} & DefaultType;

const HalfBannerDescription = ({
  bannerImage,
  bannerHeading,
  bannerSubHeading,
  bannerText,
  bannerLinkButton,
  bannerRight,
  isSmall = true,
  mobileMargin,
}: HalfBannerDescriptionType) => (
  <StyledWrapper mobileMargin={mobileMargin} positionRight={bannerRight}>
    <StyledWrapperBanner>
      <StyledBanner {...bannerImage} />
    </StyledWrapperBanner>
    <StyledContainerContentText>
      {isSmall ? (
        <StyledWrapperContentText>
          <StyleSubHeadingSmall color="gray40" variant="title3" html={bannerSubHeading} />
          <StyledHeadingSmall color="gray100" variant="title2" html={bannerHeading} />
          <StyledDescriptionSmall color="gray100" variant="body1" html={bannerText} />
          <Button
            {...bannerLinkButton}
            variant="underline"
            label={bannerLinkButton.label}
            ariaLabel={bannerLinkButton.ariaLabel}
          />
        </StyledWrapperContentText>
      ) : (
        <StyledWrapperContentText>
          <StyleSubHeading color="gray40" variant="title3" component="h3" html={bannerSubHeading} />
          <StyledHeading color="gray100" variant="title1" html={bannerHeading} />
          <StyledDescription color="gray100" variant="body1" html={bannerText} />
          <Button
            {...bannerLinkButton}
            variant="primary"
            label={bannerLinkButton.label}
            ariaLabel={bannerLinkButton.ariaLabel}
          />
        </StyledWrapperContentText>
      )}
    </StyledContainerContentText>
  </StyledWrapper>
);

export default HalfBannerDescription;
