import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';

export const StyledWrapper = styled.div<DefaultType>`
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.transparent};
  margin: ${({ mobileMargin }) => mobileMargin || `0`};
  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.sm} 36px`};
  display: flex;
  justify-content: center;

  ${breakpoint.md} {
    margin: ${({ theme, margin }) => margin || `${theme.gap.xl} 0`};
    padding: ${({ theme, padding }) => padding || theme.gap.xl};
  }
`;

export const StyledContent = styled.div<{ maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || '919px'};
`;

export const StyledTitle = styled(Typography)`
  max-width: 755px;
`;

export const StyledDescription = styled(Typography)`
  padding-top: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-top: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledProblemTitle = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.md};
  }
`;

export const StyledProblems = styled.ul<{ isProblemHeading: boolean }>`
  margin-top: ${({ theme, isProblemHeading }) => (!isProblemHeading ? theme.gap.sm : 0)};
  display: grid;
  padding-left: 10px;
  gap: ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    margin-top: ${({ theme, isProblemHeading }) => (!isProblemHeading ? theme.gap.md : 0)};
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledProblem = styled(Typography)`
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 8px;
    left: -12px;
    height: 4px;
    width: 4px;
    background-color: ${({ theme }) => theme.colors.primary50};

    ${breakpoint.md} {
      top: 10px;
    }
  }
`;
