import styled from 'styled-components';
import { breakpoint } from 'theme';

import Button from 'components/atoms/button';
import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

export const StyledWrapper = styled.div<{ margin?: string; maxWidth?: string }>`
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  margin: ${({ margin }) => margin};

  .gatsby-image-wrapper [data-main-image] {
    transition: transform 0.3s ease-in-out;
  }

  .card-with-icon__button {
    background-image: unset;
    font-weight: 400;

    span {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary50};
    }
  }

  ${breakpoint.md} {
    .card-with-icon__button {
      span {
        border-bottom: unset;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }

      .card-with-icon__button {
        span {
          display: block;
          animation: move-text-cart 0.2s linear forwards;
        }

        svg {
          animation: move-svg 0.2s linear forwards;
        }
      }

      .card-with-icon__button span {
        display: block;
      }
    }
  }
  @keyframes move-svg {
    from {
      opacity: 0;
      transform: translateX(-437%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes move-text-cart {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export const StyledTitle = styled(Typography)`
  padding-block: ${({ theme }) => `${theme.gap.xs} ${theme.gap.sm}`};

  ${breakpoint.md} {
    padding-bottom: 24px;
  }
`;

export const StyledButton = styled(Button)`
  height: 20px;

  span {
    ${breakpoint.md} {
      display: none;
    }
  }
`;

export const StyledWpImage = styled(WpImage)`
  aspect-ratio: 16 / 9;
  min-height: 192px;
  max-height: 192px;

  ${breakpoint.md} {
    max-height: 215px;
  }
`;

export const StyledPostInfo = styled(Typography)`
  padding-block: ${({ theme }) => theme.gap.sm} ${({ theme }) => theme.gap.xs};

  ${breakpoint.md} {
    padding-block: ${({ theme }) => theme.gap.smd} ${({ theme }) => theme.gap.xs};
  }

  span::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray60};
    vertical-align: middle;
    margin-inline: ${({ theme }) => theme.gap.xxs};
  }
`;
export const StyledWrapperImage = styled.div`
  cursor: pointer;
`;
