import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import typography from 'components/atoms/typography';

const titleMaxWidth = '825px';

export const StyledTitle = styled(typography)<{ isText?: string }>`
  margin: ${({ theme }) => `${theme.gap.xs} 0`};

  ${breakpoint.md} {
    margin: ${({ theme, isText }) => (isText ? `${theme.gap.sm} 0` : `${theme.gap.sm} 0`)};
    max-width: ${titleMaxWidth};
  }
`;

export const StyledSubTitle = styled(typography)`
  strong {
    text-decoration-color: transparent;
    ${breakpoint.md} {
      text-decoration-color: ${({ theme }) => theme.colors.primary50};
    }
  }
`;

export const StyledText = styled(typography)`
  margin-bottom: ${({ theme }) => theme.gap.md};
`;

export const StyledContentHeader = styled.div<{ isText?: string }>`
  width: 100%;
  padding: 10px 0;

  ${breakpoint.md} {
    padding: 0;
    display: flex;
    flex-direction: ${({ isText }) => (isText ? 'column' : 'row')};
    justify-content: space-between;
    align-items: baseline;
  }
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.wrapper};
`;

export const StyledContent = styled.div`
  max-width: ${({ theme }) => theme.wrapper};
  width: 100%;
`;

export const StyledWrapper = styled.div<DefaultType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ mobileMargin }) => mobileMargin || 0};
  padding: ${({ theme, mobilePadding }) => mobilePadding || `0 0 ${theme.gap.md} ${theme.gap.sm}`};
  padding-right: 0;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.transparent};

  ${breakpoint.md} {
    padding: ${({ theme, padding }) => padding || `${theme.gap.md} 0`};
    margin: ${({ margin }) => margin || 0};
  }

  .swiper {
    &-wrapper {
      justify-content: flex-start;
    }
  }
`;
