import styled, { css } from 'styled-components';
import { breakpoint } from 'theme';

import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

import { BannerPositionRightType } from './half-banner-description';

const IntentTextBanner = css`
  padding-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.md};
  }
`;

const IntentTextBannerSmall = css`
  padding-bottom: ${({ theme }) => theme.gap.sm};

  ${breakpoint.md} {
    padding-bottom: ${({ theme }) => theme.gap.sm};
  }
`;

export const StyledWrapper = styled.div<BannerPositionRightType>`
  display: flex;
  flex-direction: column;
  margin-block: ${({ theme }) => theme.gap.mdl};
  max-width: ${({ theme }) => theme.wrapperXxl};
  margin: ${({ theme, mobileMargin }) => mobileMargin || `${theme.gap.mdl} 0 ${theme.gap.mdl} 0`};
  ${breakpoint.md} {
    margin: 0 auto;
    margin-block: ${({ theme }) => theme.gap.xl};
    flex-direction: ${({ positionRight }) => (positionRight ? 'row-reverse' : 'row')};
  }
`;

export const StyleSubHeading = styled(Typography)`
  ${IntentTextBanner}
`;

export const StyledHeading = styled(Typography)`
  ${IntentTextBanner}
`;

export const StyledDescription = styled(Typography)`
  ${IntentTextBanner}
`;

export const StyleSubHeadingSmall = styled(Typography)`
  ${IntentTextBannerSmall}
`;

export const StyledHeadingSmall = styled(Typography)`
  ${IntentTextBannerSmall}
`;

export const StyledDescriptionSmall = styled(Typography)`
  ${IntentTextBannerSmall}
`;

export const StyledWrapperBanner = styled.div`
  ${breakpoint.md} {
    width: 49%;
  }
`;
export const StyledBanner = styled(WpImage)`
  max-height: 216px;

  ${breakpoint.sm} {
    max-height: 100%;
  }
`;

export const StyledContainerContentText = styled.div`
  padding-inline: ${({ theme }) => theme.gap.sm};
  padding-top: ${({ theme }) => theme.gap.mdl};

  ${breakpoint.md} {
    width: 50%;
    padding-inline: 0;
    padding-top: 0;
  }
`;

export const StyledWrapperContentText = styled.div`
  max-width: 440px;

  ${breakpoint.md} {
    margin-left: 126px;
    padding-block: 96px;
  }
`;
