import styled from 'styled-components';
import { breakpoint } from 'theme';
import { DefaultType } from 'types/default';

import Typography from 'components/atoms/typography';
import WpImage from 'components/atoms/wp-image';

const backgroundHeight = '484px';
const contentMarginTop = '-384px';

export const StyledWrapper = styled.div<DefaultType>`
  background-color: ${({ theme }) => theme.colors.gray0};
  padding: ${({ theme, mobilePadding }) => mobilePadding || `${theme.gap.sm} 0`};

  ${breakpoint.lg} {
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ padding }) => padding || `0`};
  }
`;

export const Background = styled.div`
  display: none;

  ${breakpoint.lg} {
    display: block;
    height: ${backgroundHeight};
    background-color: ${({ theme }) => theme.colors.grayscaleGray0};
  }
`;

export const StyledContent = styled.div`
  z-index: 1;
  padding: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin: 0 auto;
    margin-top: ${contentMarginTop};
    max-width: ${({ theme }) => theme.wrapper};
  }
`;

export const StyledHeading = styled(Typography)`
  margin-block: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    margin-bottom: ${({ theme }) => theme.gap.lg};
  }
`;

export const StyledCard = styled.div`
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column-reverse;

  ${breakpoint.lg} {
    flex-direction: row;
    height: 400px;
    width: ${({ theme }) => theme.wrapper};
  }
`;

export const StyledTextWrapper = styled.div`
  padding: ${({ theme }) => theme.gap.sm};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.gap.sm};

  ${breakpoint.lg} {
    padding: ${({ theme }) => theme.gap.lg};
    width: 50%;
    justify-content: center;
    gap: ${({ theme }) => theme.gap.smd};
  }
`;

export const StyledWpImage = styled(WpImage)`
  height: auto;
  width: 100%;

  ${breakpoint.lg} {
    height: 400px;
    width: 50%;
  }
`;

export const StyledWrapperImageCaseStudy = styled.div`
  width: fit-content;
`;
