import React from 'react';
import { Link } from 'gatsby';
import { DefaultType } from 'types/default';
import { PostType } from 'types/know-how-hub-page';

import Typography from 'components/atoms/typography';
import WpImage, { WpImageType } from 'components/atoms/wp-image';

import {
  Background,
  StyledCard,
  StyledContent,
  StyledHeading,
  StyledTextWrapper,
  StyledWpImage,
  StyledWrapper,
  StyledWrapperImageCaseStudy,
} from './case-studies.styles';

export type CaseStudiesType = {
  subTitle: string;
  heading: string;
  imageCaseStudy: WpImageType;
  caseStudyPost: PostType;
} & DefaultType;

const CaseStudies = ({ subTitle, heading, caseStudyPost, imageCaseStudy }: CaseStudiesType) => {
  const authorData = caseStudyPost?.knowHowPostData?.postTemplate.find(
    (item) => item.firstName && item.lastName && item.position
  );
  const { firstName, lastName, position } = authorData || {};
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';
  return (
    <StyledWrapper>
      <Background />
      <StyledContent>
        <Typography variant="title4" color="gray40" html={subTitle} />
        {heading ? <StyledHeading variant="title2" component="h2" html={heading} /> : null}

        <Link to={caseStudyPost?.link ? caseStudyPost.link : '#'}>
          <StyledCard>
            <StyledTextWrapper>
              {imageCaseStudy ? (
                <StyledWrapperImageCaseStudy>
                  <WpImage {...imageCaseStudy} />
                </StyledWrapperImageCaseStudy>
              ) : null}
              {caseStudyPost?.title ? (
                <Typography
                  variant="title2"
                  component="h2"
                  color="gray100"
                  html={caseStudyPost.title}
                />
              ) : null}
              <div>
                <Typography variant="body1" color="gray100" html={fullName} />
                <Typography variant="body2" color="gray80" html={position} />
              </div>
            </StyledTextWrapper>
            {caseStudyPost?.featuredImage ? (
              <StyledWpImage {...caseStudyPost.featuredImage.node} />
            ) : null}
          </StyledCard>
        </Link>
      </StyledContent>
    </StyledWrapper>
  );
};

export default CaseStudies;
